.section {
  background: #fff;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 100% 0%;
  p {
    color: #222 !important;
  }
}
.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    line-height: 3.5rem;
    font-size: 3em;
  }
}

.image {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .content,
  .section,
  .container {
    height: auto;
  }
  .container {
    margin-top: 15px;
  }
  .content {
    h1 {
      margin-top: 2em;
      margin-bottom: 1em;
    }
    p {
      margin-bottom: 2em;
    }
  }
}

@media screen and (min-width: 1100px) {
  .content {
    h1 {
      margin-top: 1em;
      margin-bottom: 0.5em;
    }
    p {
      max-width: 300px;
      font-size: 0.9em;
    }
  }
}
