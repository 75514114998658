@import "../variables.scss";

.section {
  background: url("../../assets/images/art.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 100% 0%;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  p {
    color: #222 !important;
  }
}

.content {
  max-width: 600px;
  margin-left: 30vw;
  background: rgba(0, 178, 81, 0.9);
  padding: 30px;
  h1 {
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.5rem;
    display: block;
    color: #fff !important;
  }
  p {
    margin-top: 2em;
    color: #fff !important;
  }
}
.button {
  margin-top: 50px;
  color: $primary;
}

@media screen and (max-width: 768px) {
  .section {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .content {
    max-width: 100%;
    margin-left: 0;
    margin: 15px;
    background: rgba(0, 178, 81, 0.9);
    padding: 30px;
  }
}
