.section {
  // background: url("../../assets/images/the-green-book-about.jpg");
  background: url("../../assets/images/esso.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #040404;
  p {
    color: #999 !important;
  }
}

.content {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  h1 {
    line-height: 3.5rem;
    color: #00b251;
  }
  h2 {
    margin-top: 0.5em;
  }
  h2,
  h3 {
    color: #fff;
  }
}

.documentaryCTA {
  text-align: center;
  display: inline-block;
  margin-top: 2rem;
  margin-right: 4em;
  img {
    margin: auto;
  }
  div {
    margin: 0.5rem;
  }
}
.videoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (min-width: 1400px) {
  .section {
    background: url("../../assets/images/esso.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #040404;
  }
}

@media screen and (max-width: 768px) {
  .section {
    position: fixed;
    height: 100%;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }
  .content {
    text-align: center;
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
    h3 {
      font-size: 1.1em;
    }
  }
  .videoWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  .documentaryCTA {
    margin-top: 2rem;
    margin-right: 0;
  }
}
