@import "../variables.scss";
.section {
  background-image: url("../../assets/images/community.jpg");
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  h1 {
    max-width: 300px;
    margin-bottom: 1em;
  }
  p {
    color: #222 !important;
  }
}

.content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  z-index: 2;
  h1 {
    line-height: 3.5rem;
    color: $primary;
    font-size: 3em;
  }
  p {
    color: #fff !important;
  }
}

.documentaryCTA {
  text-align: center;
  display: inline-block;
  margin-top: 2rem;
  margin-right: 2rem;
  img {
    margin: auto;
  }
  div {
    margin: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .section {
    position: fixed;
    height: 100%;
    h1 {
      margin-bottom: 0.5em;
    }
  }
}
