@import "../variables.scss";
.section {
  background: #000;
  background-image: url("../../assets/images/skyline.jpg");
  background-position: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  p {
    max-width: 830px;
  }
}
.title {
  position: relative;
  padding-top: 4em;
  padding-bottom: 2em;
  color: #fff;
}
.thumbWrapper {
  padding-right: 0 !important;
  padding-left: 0 !important;
  justify-content: space-evenly;
}

.thumb {
  background: #000;
  display: block;
  border: 5px solid rgba(0, 0, 0, 0);
  position: relative;
  height: 296px;

  h4 {
    color: #fff;
    font-size: 0.8rem;
    text-align: center;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.2;
    transition: all 0.8s ease-in-out;
  }
  &:hover img {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    border: 5px solid $secondary;
  }
}
.subContentTitle {
  color: #fff;
  padding-top: 2em;
}
.subContent {
  color: #fff;
  font-size: 0.8em;
  line-height: 1.7em;
}

.active {
  border: 5px solid #ffc400 !important;
}

@media screen and (max-width: 1100px) {
  .section {
    background-position: 0;
    background-image: none;
    flex-direction: row;
  }
  .thumbWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .thumb {
    background: #000;
    display: block;
    border: 5px solid rgba(0, 0, 0, 0);
    position: relative;
    height: 184px;
    h4 {
      color: #fff;
      font-size: 0.8rem;
      text-align: center;
    }
    img {
      // position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0.2;
      transition: all 0.8s ease-in-out;
    }
    &:hover img {
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      border: 5px solid $secondary;
    }
  }
}

@media screen and (max-width: 600px) {
  .section {
    background-position: 0;
    background-size: inherit;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
  .thumbWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    a {
      overflow: hidden;
    }
  }
  .subContent {
    color: #fff;
    font-size: 1em;
    line-height: auto;
  }
}
