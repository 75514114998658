@import "../variables.scss";

.section {
  background-image: url("../../assets/images/casestudy.jpg");
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  small {
    color: #fff;
    display: block;
    margin-top: 2em;
  }
}
.container {
  height: 100vh;
  display: flex;
  align-items: center;
  div {
    margin-left: 20vw;
  }
}
.title {
  color: #fff;
}

.button {
  margin-top: 50px;
  color: #fff !important;
  background: $primary !important;
  border-color: $primary !important;
}
p {
  max-width: 450px;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .section {
    position: fixed;
    height: 100%;
  }
  .container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    div {
      margin-left: 0;
      padding: 20px;
    }
  }
}
