@import "../variables.scss";
.section {
  background-image: url("../../assets/images/opportunity-zone-map.jpg");
  background-position: 20vw 0;
  background-color: #3db2e7;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  padding-top: 10%;
}
.title {
  color: #fff;
}

.button {
  margin-top: 50px;
  color: $info;
}
p {
  max-width: 450px;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .section {
    position: fixed;
    height: 100%;
    padding-top: 100px;
  }
}
