@import "../variables.scss";
.section {
  background-image: url("../../assets/images/ventures-bg.jpg");
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  padding-top: 10%;
  h4,
  p {
    color: #fff;
  }

  p {
    margin-top: 2em;
    max-width: 100%;
  }
}
.subtitle {
  margin-bottom: 4em;
}
.title {
  color: $primary;
}

.button {
  margin-top: 50px;
}
.carousel {
  padding-top: 50px;
}
.thumb {
  display: flex;
  align-items: center;
  border: 5px solid rgba(0, 0, 0, 0);
  position: relative;
  height: 200px;
  max-width: 70%;
  margin: auto;
  h5 {
    opacity: 1;
  }
  svg {
    opacity: 0;
  }

  img {
    transition: all 0.8s ease-in-out;
    width: calc(100% + 45%);
    border: thin solid #fff;
  }
  &:hover img {
    filter: blur(5px);
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    border: 5px solid $secondary;
  }
  &:hover ~ h5 {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  &:hover ~ svg {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }
}
.thumbWrapper {
  text-align: center;
  svg {
    opacity: 0;
    width: 15px;
  }
}
.thumbTitle {
  padding-top: 20px !important;
  font-size: 0.7rem !important;
}

@media screen and (max-width: 1100px) {
  .carousel {
    padding-top: 0px;
  }
  .thumb {
    height: 200px;
  }
}

@media screen and (max-width: 768px) {
  .section {
    background-size: inherit;
    height: auto;
    h4 {
      margin-top: 2em;
      margin-bottom: 1em;
    }
    h2 {
      font-size: 2em;
    }
    h5 {
      font-size: 0.9em;
    }
  }
}
